"use client";

import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSignUpStore } from "@/stores/signUpStore";
import { shallow } from "zustand/shallow";

const steps = ["ข้อมูลธนาคาร", "ตั้งລະຫັດຜ່ານ"];

export default function SignUpStepper() {
  const [step] = useSignUpStore((state) => [state.step], shallow);

  return (
    <Stepper activeStep={step} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
