/**
 * สถานะและการกระทำของการลงทะเบียนผู้ใช้งาน
 */
import { apiPath } from "@/configs/apiPath";
import authConfig from "@/configs/auth";
import http from "@/libs/http";
import { getApiKeyHeaders } from "@/libs/utils";
import { RegisterPayload, User } from "@/@types/user";
import { ApiKeyHeaders } from "@/@types/util";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";
import { useAuthStore } from "./authStore";

type State = {
  loading: boolean;
  /**
   * ขั้นตอนປະຈຸບັນของการลงทะเบียน
   */
  step: 0 | 1 | 2;
  /**
   * PAYLOAD ที่จะส่งไปยัง API
   */
  input?: {
    phoneNumber?: string;
    username?: string;
    password?: string;
    bankCode?: string;
    bankAccountNumber?: string;
    fullName?: string;
    captchaToken?: string;
  };
};

type Actions = {
  /**
   * เปลี่ยนไปยังขั้นตอนถัดไปของการลงทะเบียน
   */
  nextStep: () => void;
  /**
   * เปลี่ยนไปยังขั้นตอนก่อนหน้าของการลงทะเบียน
   */
  prevStep: () => void;
  /**
   * อัปเดท INPUT
   */
  updateInput: (input: State["input"]) => void;
  /**
   * ลงทะเบียน
   */
  register: (payload: RegisterPayload) => Promise<User | string>;
  verifyBankAccount: (payload: {
    bankCode: string;
    bankAccountNumber: string;
  }) => Promise<any>;
};

/**
 * สถานะและการกระทำของการลงทะเบียนผู้ใช้งาน
 */
export const useSignUpStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      loading: false,
      /**
       * ขั้นตอนປະຈຸບັນของการลงทะเบียน
       */
      step: 0,
      /**
       * เปลี่ยนไปยังขั้นตอนถัดไปของการลงทะเบียน
       */
      nextStep: () => set((state) => ({ step: state.step + 1 })),
      /**
       * เปลี่ยนไปยังขั้นตอนก่อนหน้าของการลงทะเบียน
       */
      prevStep: () => set((state) => ({ step: state.step - 1 })),
      /**
       * อัปเดท INPUT
       */
      updateInput: (input) =>
        set((state) => ({ input: { ...state.input, ...input } })),
      /**
       * ลงทะเบียน
       */
      register: async (payload) => {
        set(() => ({ loading: true }));
        try {
          const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
          const ref = localStorage.getItem("ref");
          // register
          const { data } = await http.post(apiPath.auth.register, {
            username: payload.phoneNumber,
            password: payload.password,
            password_confirmation: payload.password,
            fullname: payload.fullName,
            bank_code: payload.bankCode,
            captchaToken: payload.captchaToken,
            bank_account_number: payload.bankAccountNumber,
            aff_code: ref || "",
          });
          // wait 2 seconds
          await new Promise((resolve) => setTimeout(resolve, 2000));
          const { status, msg, data: userData } = data;
          const accessToken = userData.token;
          // set token
          window.localStorage.setItem(
            authConfig.storageTokenKeyName,
            accessToken
          );
          // set cookie
          window.document.cookie = `${authConfig.storageTokenKeyName}=${accessToken}`;
          // fetch me
          const fetchMe = await fetch(apiPath.auth.me, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
              agent: xApiKeyHeaders.agent,
              time: xApiKeyHeaders.time.toString(),
              "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
            },
          });
          const me = await fetchMe.json();
          if (!status) {
            return msg;
          }
          // set user data
          window.localStorage.setItem(
            authConfig.storageUserDataKeyName,
            JSON.stringify(me.data)
          );
          // set user
          useAuthStore.setState((state) => ({
            user: me.data,
            accessToken,
          }));
          set(() => ({ loading: false }));
          return userData as User;
        } catch (err: any) {
          set(() => ({ loading: false }));
          return `ไม่สามารถลงทะเบียนได้: ${err.message === `Cannot read properties of null (reading 'token')`
              ? 'มีชื่อผู้ใช้งานในระบบแล้ว กรุณาลงทะเบียนด้วยผู้ใช้งานอื่น'
              : err.message
            }`;
        }
      },
      /**
       *
       * ตรวจสอบบัญชีธนาคาร
       */
      verifyBankAccount: async (payload) => {
        set(() => ({ loading: true }));
      },
    }))
  ),
  Object.is
);
